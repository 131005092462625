import React, { Component } from 'react';
import { array, bool, func, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import unionWith from 'lodash/unionWith';
import omit from 'lodash/omit';
import classNames from 'classnames';

import config from '../../config';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive, isMainSearchTypeKeywords } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { Footer, LayoutWrapperTopbar, NamedLink, Page } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { setActiveListing, updateSearchPagePreviousPath } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';

import BackArrow from '../../assets/icons/back-arrow.png'

import FilterComponent from './FilterComponent';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';

import css from './SearchPage.module.css';
import SectionSearch from '../LandingPage/SectionSearch/SectionSearch';
import SearchEmptyStateIcon from '../../assets/icons/search-empty-state.png'
import Spacer, { MEDIUM_SPACER } from '../../components/Spacer/Spacer';
import { addCurrentUserFavoriteListing, removeCurrentUserFavoriteListing } from '../../ducks/user.duck';
import { partsConfig } from '../../config/parts-config';

import bike from '../../assets/icons/categories/medium/bike.png'
import parts from '../../assets/icons/categories/medium/part.png'

import bmx_dirt from '../../assets/icons/categories/medium/bmx.png'
import city from '../../assets/icons/categories/medium/city.png'
import electric from '../../assets/icons/categories/medium/electric.png'
import folding from '../../assets/icons/categories/medium/folding.png'
import gravel from '../../assets/icons/categories/medium/gravel.png'
import mountain from '../../assets/icons/categories/medium/mountain.png'
import other from '../../assets/icons/categories/medium/other.png'
import road from '../../assets/icons/categories/medium/road.png'

import brakes from '../../assets/icons/categories/medium/brakes.png'
import cockpit from '../../assets/icons/categories/medium/cockpit.png'
import drivetrain from '../../assets/icons/categories/medium/drivetrain.png'
import frame from '../../assets/icons/categories/medium/frame.png'
import seat from '../../assets/icons/categories/medium/seat.png'
import suspension from '../../assets/icons/categories/medium/suspension.png'
import tyres_tubes from '../../assets/icons/categories/medium/tyres_n_tubes.png'
import wheels from '../../assets/icons/categories/medium/wheels.png'

import Loader from '../../components/Loader/Loader';
import TopBarWithReturn from '../../components/TopBarWithReturn/TopBarWithReturn';
import { listingCategoriesRoutingConfiguration } from '../../config/helpers/listingCategories';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

const validUrlQueryParamsFromProps = props => {
  const { location, filterConfig } = props;
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  return validURLParamsForExtendedData(searchInURL, filterConfig);
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isFiltersOpenOnMobile: false,
    };

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  componentDidMount() {
    const { updatePreviousPath, history } = this.props

    const prevPath = history.location?.state?.prevPath

    if(prevPath && !prevPath.includes('search')) {
      updatePreviousPath(prevPath)
    }
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames, isLiveEdit) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};

        const updatedURLParamsKeys = Object.keys(updatedURLParams)
        const updateURLParamsIncludeCategory = updatedURLParamsKeys
          .some(key => ['pub_listingType', 'pub_category', 'pub_subCategory'].includes(key))
        
        if(updateURLParamsIncludeCategory) {
          return { currentQueryParams: {...updatedURLParams} }
        }

        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const filteredsearchParams = Object.fromEntries(
            Object.entries(this.state.currentQueryParams).filter(([key, value]) => value !== 'undefined')
          )
          const search = cleanSearchFromConflictingParams(filteredsearchParams, sortConfig, filterConfig);

          const pathParams = search['pub_listingType'] === undefined && search['pub_category'] === undefined  && search['pub_subCategory'] === undefined
            ? this.props.match.params
            : { type: search['pub_listingType'], category: search['pub_category'], subCategory: search['pub_subCategory'] }

          const queryParams = Object.fromEntries(
            Object.entries(search).filter(([key, _value]) => !['pub_listingType', 'pub_category', 'pub_subCategory'].includes(key))
          )

          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), pathParams, queryParams));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      onActivateListing,
      onLocaleChange,
      currentLocale,
      likedListingsIds,
      addUserFavorite,
      removeUserFavorite,
      currentUserId,
      history,
      previousPath
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const listingType = this.props.match.params.type
    const listingCategory = this.props.match.params.category
    const listingSubCategory = this.props.match.params.subCategory

    const currentCategory = listingSubCategory || listingCategory || listingType

    const topBarLabel = listingSubCategory !== undefined
      ? intl.formatMessage({ id: `sub_categories.${currentCategory}` })
      : intl.formatMessage({ id: `categories.${currentCategory || 'all' }` })

    const availableSpecifications = partsConfig
      .find(partConfig => partConfig.id === 'parts_category')
      .config.options
      .find(category => category.key === listingCategory)
      ?.subcategories
      ?.find(subCategory => subCategory.key === listingSubCategory)
      ?.specifications
      .filter(spec => spec.isFilterable === true)

    const availableSubSpecifications = availableSpecifications
      ?.map(spec => spec.config.options?.map(subSpec => subSpec.options))
      ?.flat(Infinity)
      ?.filter(spec => spec !== undefined)
      ?.filter((obj, index, self) => index === self.findIndex((o) => o.id === obj.id))

    const partBrands = partsConfig.find(partConfig => partConfig.id === 'brand')
    const partConditions = partsConfig.find(partConfig => partConfig.id === 'condition')

    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);
    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const availableFilters = isKeywordSearch
      ? filterConfig.filter(f => f.type !== 'KeywordFilter' && f.isFilterable === true )
      : filterConfig

    availableFilters.sort((a, b) => a.sortingOrder - b.sortingOrder);

    const baseFilters = availableFilters.filter(f => f.isBaseFilter === true)
    const bikeFilters = availableFilters
    const partFilters = baseFilters.concat(partBrands).concat(partConditions)

    const filterSpecifications = listingType === undefined
      ? baseFilters
      : listingType === 'bike'
        ? bikeFilters
        : availableSpecifications === undefined 
          ? partFilters
          : partFilters.concat(availableSpecifications).concat(availableSubSpecifications)

    // Selected aka active filters
    const selectedFilters = validFilterParams(validQueryParams, filterConfig);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && hasPaginationInfo;

    const onOpenMobileModal = () => {
      this.setState({ 
        isMobileModalOpen: true,
        isFiltersOpenOnMobile: true,
        initialQueryParams: validQueryParams
      });
    }
  
    const onCloseMobileModal = () => {
      this.setState({ 
        isMobileModalOpen: false,
        isFiltersOpenOnMobile: false,
        initialQueryParams: null,
      });
    }

    const host = typeof window !== 'undefined' && window.location && window.location.host;

    const { title, description, schema } = createSearchResultSchema(
      host,
      listings,
      searchInURL || {},
      intl,
      listingType,
      listingCategory
    );

    const hasNoResult = listingsAreLoaded && totalItems === 0;

    const noResultsInfo = hasNoResult ? (
      <div className={css.searchPageEmptyStateWrapper}>
        <div className={css.searchPageEmptyStateRoot}>
          <img className={css.searchPageEmptyStateIcon} src={SearchEmptyStateIcon}/>
          <Spacer size={MEDIUM_SPACER}/>
          <p className={css.searchPageEmptyStateMessage}>{intl.formatMessage({ id: 'search_page.empty_state.message' })}</p>
        </div>
      </div>
    ) : null

    const handleSubmit = (values) => {
      const { history } = this.props;
      const searchParams = { ...urlQueryParams, keywords: values?.keywords }
      const pathParams = this.props.match?.params || {}

      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), pathParams, searchParams));
    }

    const searchInitialValues = () => {
      const { keywords } = parse(location.search)

      return keywords
    }

    const categoryImages = {
      bike, parts,
      brakes, cockpit, drivetrain, frame, seat, suspension, tyres_tubes, wheels,
      bmx_dirt, city, road, mountain, gravel, folding, electric, other
    }

    const handleBackClick = () => {
      const pathParams = listingSubCategory !== undefined 
        ? { type: listingType, category: listingCategory }
        : listingCategory !== undefined
          ? { type: listingType }
          : listingType !== undefined
            ? {}
            : undefined

      pathParams !== undefined
        ? history.push(createResourceLocatorString('SearchPage', routeConfiguration(), pathParams, {}))
        : history.push(previousPath)
    }

    const availableCategoriesRouting = listingCategoriesRoutingConfiguration.find(t => t.key === listingType)?.categories
    const availableRoutings = listingType === undefined 
      ? listingCategoriesRoutingConfiguration
      : listingCategory === undefined ? availableCategoriesRouting : null

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutWrapperTopbar className={css.topbarRoot}>
          <div className={css.mobileTopbar}>
            <TopBarWithReturn
              rootClassName={css.topbar}
              topBarLabel={topBarLabel}
              onBackClick={() => handleBackClick()}
              history={history}
              withMenu={true}
              onLocaleChange={onLocaleChange}
              currentLocale={currentLocale}
            />

            <div className={css.searchBarWrapper}>
              <SectionSearch
                rootClassName={css.searchBarRoot}
                onSubmit={handleSubmit}
                initialValues={{ keywords: searchInitialValues() }}
                isMobile
                showFilters
                onFilterOpen={onOpenMobileModal}
              />
            </div>
          </div>
          <div className={css.desktopTopbar}>
            <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale}/>
          </div>
        </LayoutWrapperTopbar>
        <div className={css.container}>
          <div className={css.searchResultContainer}>
            <div className={css.searchBarContainer}>
              <SearchFiltersMobile
                className={css.searchFiltersMobileMap}
                urlQueryParams={validQueryParams}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={onOpenMobileModal}
                onCloseModal={onCloseMobileModal}
                initialQueryParams={this.state.initialQueryParams}
                resetAll={this.resetAll}
                selectedFiltersCount={selectedFiltersCountForMobile}
                noResultsInfo={noResultsInfo}
                isFiltersOpenOnMobile={this.state.isFiltersOpenOnMobile}
                hideTopBar={true}
              >
                <div className={css.filterTopBar}>
                  <img onClick={() => onCloseMobileModal()} className={css.topBarBackArrow} src={BackArrow}/>
                  <label className={css.topBarLabel}>{intl.formatMessage({id: 'search_page.mobile_filters.label'})}</label>
                  <span className={css.topBarResetAll} onClick={() => this.resetAll()}>{intl.formatMessage({id: 'search_page.mobile_filters.reset'})}</span>
                </div>
                {filterSpecifications.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.id}`}
                      idPrefix="SearchFiltersMobile"
                      filterConfig={config}
                      urlQueryParams={validQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      liveEdit
                      showAsPopup={false}
                      intl={intl}
                    />
                  );
                })}
              </SearchFiltersMobile>
            </div>
            { availableRoutings && <div className={css.categorySelectionRoot}>
              <div className={css.categorySelectionContainer}>
                {availableRoutings.map(type => (
                  <NamedLink key={type.key} name='SearchPage' params={type.pathParams}>
                    <div className={css.categorySelection}>
                      <div className={css.categoryImageWrapper}>
                        <img className={css.categoryImage} src={categoryImages[type.key]}/>
                      </div>
                      <span className={css.categoryTitle}>
                        {intl.formatMessage({ id: `categories.${type.key}` })}
                      </span>
                    </div>
                  </NamedLink>
                  
                ))}
              </div>
            </div> }
            <div className={css.dividerSmall}/>
            <div className={css.desktopWrapper}>
              <SearchFiltersPrimary>
                {filterSpecifications.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.id}`}
                      idPrefix="SearchFiltersMobile"
                      filterConfig={config}
                      urlQueryParams={validQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      showAsPopup={false}
                      liveEdit
                      intl={intl}
                    />
                  );
                })}
              </SearchFiltersPrimary>

              <div
                className={classNames(css.listingsForMapVariant, {
                  [css.newSearchInProgress]: !listingsAreLoaded,
                })}
              >
                {searchListingsError ? (
                  <h2 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </h2>
                ) : null}
                { searchInProgress ? <div className={css.loaderWrapper}><Loader/></div> : null }
                { noResultsInfo ? noResultsInfo : null }
                { !searchInProgress && !hasNoResult && <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={listings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  pathParams={this.props.match?.params}
                  setActiveListing={onActivateListing}
                  intl={intl}
                  likedListingsIds={likedListingsIds}
                  addUserFavorite={addUserFavorite}
                  removeUserFavorite={removeUserFavorite}
                  currentUserId={currentUserId}
                  resultsCount={totalItems}
                /> }
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchMapListingIds,
    activeListingId,
    previousPath
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  const { currentUserFavoriteListingsIds, currentUser } = state.user

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    activeListingId,
    likedListingsIds: currentUserFavoriteListingsIds,
    currentUserId: currentUser?.id?.uuid,
    previousPath
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  addUserFavorite: (listingId, listingType) => dispatch(addCurrentUserFavoriteListing(listingId, listingType)),
  removeUserFavorite: (listingId) => dispatch(removeCurrentUserFavoriteListing(listingId)),
  updatePreviousPath: (path) => dispatch(updateSearchPagePreviousPath(path))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
