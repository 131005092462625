import React, { useState } from 'react'

import css from './TopBarWithReturn.module.css'
import BackArrow from '../../assets/icons/back-arrow.png'
import classNames from 'classnames'
import Button from '../Button/Button'

import CloseIcon from '../../assets/icons/x-12.png'
import BurgerMenuIcon from '../../assets/icons/menu.png'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import Modal from '../Modal/Modal'
import TopbarMobileMenu from '../Topbar/TopbarMobileMenu/TopbarMobileMenu'
import { authenticationInProgress, logout } from '../../ducks/Auth.duck'
import { manageDisableScrolling } from '../../ducks/UI.duck'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { pathByRouteName } from '../../util/routes'
import routeConfiguration from '../../routing/routeConfiguration'
import Logo from '../Logo/Logo'
import NamedLink from '../NamedLink/NamedLink'

const TopBarWithReturn = props => {
  const {
    rootClassName,
    onBackClick,
    topBarLabel,
    onLabelClick,
    withMenu,
    intl,
    isAuthenticated,
    onLocaleChange,
    currentLocale,
    currentUser,
    currentPage,
    onManageDisableScrolling,
    notificationCount,
    onLogout,
    history
  } = props

  const [menuOpen, setMenuOpen] = useState(false)

  const labelClassName = onLabelClick 
    ? css.topBarTitleClickable
    : css.topBarTitle

  const className = classNames(css.root, rootClassName)

  const handleLogout = () => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration())

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path)
      } else if (typeof window !== 'undefined') {
        window.location = path
      }
    })
  }

  const renderMenuHandler = () => (
    <>
      {menuOpen ? (
        <Button
          onClick={() => setMenuOpen(false)}
          rootClassName={css.menu}
          title='Close modal'
        >
          <img className={css.closeIcon} src={CloseIcon}/>
        </Button>
        ) : (
          <Button
            rootClassName={css.menu}
            onClick={() => setMenuOpen(true)}
            title={intl.formatMessage({ id: 'top_bar.menu_icon_label' })}
          >
            <img className={css.menuIcon} src={BurgerMenuIcon}/>
        </Button>
      )}
    </>
  )

  const renderLogoComponent = () => (
    <div className={css.home} onClick={() => setMenuOpen(false)}>
      <NamedLink
        name="LandingPage"
        title={intl.formatMessage({ id: 'top_bar.logo_icon_label' })}
      >
        <Logo className={css.logo} format="mobile" />
      </NamedLink>
    </div>
  )

  return (
    <>
      <div className={className}>
        {
          !menuOpen ? (
            <>
              <div className={css.backActionWrapper} onClick={onBackClick}>
                <img className={css.returnAction} src={BackArrow}/>
              </div>
              <label onClick={onLabelClick} className={labelClassName}>{topBarLabel}</label>
            </>
          ) : (
            <>{renderLogoComponent()}</>
          )
        }
        { withMenu && renderMenuHandler() }
      </div>
      <Modal
          id="TopbarMobileMenu"
          className={css.mobileModal}
          containerClassName={css.mobileModalContainer}
          headerClassName={css.mobileModalHeader}
          isOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          usePortal={false}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <TopbarMobileMenu
            isAuthenticated={isAuthenticated}
            currentUser={currentUser}
            onLogout={() => handleLogout()}
            notificationCount={notificationCount}
            currentPage={currentPage}
            intl={intl}
            onLocaleChange={onLocaleChange}
            currentLocale={currentLocale}
            onClose={() => setMenuOpen(false)}
          />
        </Modal>
    </>
  )
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth

  const {
    currentUser,
    currentUserNotificationCount: notificationCount,
  } = state.user

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    notificationCount,
    isAuthenticated,
  };
}

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
})

export default compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopBarWithReturn)
